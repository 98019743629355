import React, { useState } from 'react';
import { Card, CardContent, Typography, IconButton, Paper, InputBase, Button, Grid, Snackbar, Alert, Box, RadioGroup, FormControlLabel, Radio, TextField, MenuItem, FormControl, InputLabel, Select, CircularProgress } from '@mui/material';
import { Add, Remove } from '@mui/icons-material'
import { Wallet } from '@mercadopago/sdk-react';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Carrito = ({ carrito, setCarrito, stock, setStock }) => {
  const [preferenceId, setPreferenceId] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const [envio, setEnvio] = useState('');
  const [costoEnvio, setCostoEnvio] = useState(null);
  const [codigoPostal, setCodigoPostal] = useState('');
  const [direccion, setDireccion] = useState('');
  const [provincia, setProvincia] = useState('AR-B');
  const [loadingEnvio, setLoadingEnvio] = useState(false);
  const [telefono, setTelefono] = useState('');
  const [nombreApellido, setNombreApellido] = useState('');
  const [loadingBuy, setLoadingBuy] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const handleEnvioChange = (event) => {
    const opcion = event.target.value;
    setEnvio(opcion);
    setCostoEnvio(opcion === 'envio' ? null : 0);
  };

  const handleCalcularEnvio = async () => {
    if (!codigoPostal || !direccion || !provincia || !nombreApellido) {
      setSnackbarMessage('Por favor complete todos los campos de envío.');
      setSnackbarSeverity('error');
      setShowSnackbar(true);
      return;
    }
    setLoadingEnvio(true);
    const pesoTotal = carrito.reduce((acc, producto) => acc + (producto.cantidad * (producto.categoria.peso || 500)), 0);
    const alturaTotal = carrito.reduce((acc, producto) => acc + (producto.cantidad * (producto.categoria.alto || 10)), 0);
    const anchoTotal = carrito.reduce((acc, producto) => acc + (producto.cantidad * (producto.categoria.ancho || 10)), 0);
    const largoTotal = carrito.reduce((acc, producto) => acc + (producto.cantidad * (producto.categoria.largo || 10)), 0);
    
    // console.log("pesoTotal", pesoTotal);
    // console.log("alturaTotal", alturaTotal);
    // console.log("anchoTotal", anchoTotal);
    // console.log("largoTotal", largoTotal);

    try {

      const tokenResponse = await fetch(`${process.env.REACT_APP_BACK_URL}/getToken`);
      const tokenData = await tokenResponse.json();
      const token = tokenData.token;
  
      const response = await fetch('https://api.correoargentino.com.ar/micorreo/v1/rates', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          customerId: '0001450424',
          postalCodeOrigin: '1834',
          postalCodeDestination: codigoPostal,
          deliveredType: 'D',
          dimensions: {
            weight: pesoTotal,
            height: alturaTotal,
            width: anchoTotal,
            length: largoTotal,
          },
        }),
      });
  
      const data = await response.json();

      const rates = data.rates;
      const clasicoRate = rates.find(rate => rate.productName === 'Correo Argentino Clasico');
      if (clasicoRate) {
        setCostoEnvio(clasicoRate.price);
      } else {
        setCostoEnvio(null);
      }

    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoadingEnvio(false);
    }
  };

  const subtotal = carrito.reduce((acc, producto) => acc + (producto.precio_descuento || producto.precio) * producto.cantidad, 0);
  const total = subtotal + costoEnvio;

  const removerDelCarrito = (producto) => {
    const carritoActualizado = carrito.filter(
      (item) => !(item.id === producto.id && item.talla === producto.talla && item.color === producto.color)
    );
    setCarrito(carritoActualizado);
    setPreferenceId(null);
    setLoadingBuy(false);
  }

  const reducirCantidad = (producto) => {
      const productoExistente = carrito.find(
          (item) => item.id === producto.id && item.talla.id === producto.talla.id && item.color.id === producto.color.id
        );
    
        if (productoExistente.cantidad > 1) {
          // Si el producto tiene más de una unidad, disminuye la cantidad
          const carritoActualizado = carrito.map((item) =>
            item.id === producto.id && item.talla.id === producto.talla.id && item.color.id === producto.color.id
              ? { ...item, cantidad: item.cantidad - 1 }
              : item
          );
          setCarrito(carritoActualizado);
          setPreferenceId(null);
          setLoadingBuy(false);
        } else {
          // Si solo queda una unidad, elimina el producto del carrito
          const carritoActualizado = carrito.filter(
            (item) => !(item.id === producto.id && item.talla.id === producto.talla.id && item.color.id === producto.color.id) 
          );
          setCarrito(carritoActualizado);
          setPreferenceId(null);
          setLoadingBuy(false);
        }
    };

  // Función para aumentar la cantidad de un producto
  const aumentarCantidad = (producto) => {
    const productoExistente = carrito.find(
      (item) => item.id === producto.id && item.talla.id === producto.talla.id && item.color.id === producto.color.id
    );
  
    if (productoExistente.cantidad + 1 > productoExistente.stock) {
      setSnackbarMessage('No hay suficiente stock disponible');
      setSnackbarSeverity('error');
      setShowSnackbar(true);
      return;
    }
  
    const carritoActualizado = carrito.map((item) =>
      item.id === producto.id && item.talla.id === producto.talla.id && item.color.id === producto.color.id
        ? { ...item, cantidad: item.cantidad + 1 }
        : item
    );
    setCarrito(carritoActualizado);
    setPreferenceId(null);
    setLoadingBuy(false);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSnackbar(false);
  };

  const handleBuy = async () => {
    try {
      setLoadingBuy(true);
      const id = await crearPreferencia();
      if (id) {
        setPreferenceId(id);
      }
    } catch (error) {
      console.error('Error al crear la preferencia:', error);
    }
    };

  const crearPreferencia = async () => {
    if (carrito.length > 0) {
      try {

        const additionalInfo = {
          shipping_location: direccion || 'Retiro en domicilio',
        };

        var items = carrito.map((item) => ({
          title: `${item.nombre} ${item.color.nombre} (${item.talla.nombre})`,
          quantity: item.cantidad,
          unit_price: Number(item.precio_descuento || item.precio),
          currency_id: 'ARS',
        }));

        if(costoEnvio !== null){
          items.push({ title: 'Envío', quantity: 1, unit_price: costoEnvio, currency_id: 'ARS' });
        }
        
        const preference = {
          items: items,
          back_urls: {
            success: 'https://oneway.ar',
            failure: 'https://oneway.ar',
            pending: 'https://oneway.ar',
          },
          auto_return: 'approved',
          notification_url: `${process.env.REACT_APP_BACK_URL}/webhook?shipping_location=${encodeURIComponent(additionalInfo.shipping_location)}&shipping_province=${encodeURIComponent(provincia)}&codigoPostal=${encodeURIComponent(codigoPostal)}&telefono=${encodeURIComponent(telefono)}&nombreApellido=${encodeURIComponent(nombreApellido)}`,
        };

        const response = await fetch(`${process.env.REACT_APP_BACK_URL}/create_preference`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(preference),
          withCredentials: true,
        });
        const data = await response.json();

        return data.id; // Devolvemos el ID de la preferencia
      } catch (error) {
        console.error('Error al crear la preferencia:', error);
      }
    }
    return null;
  };

  return (
    <div style={{ padding: 20 }}>
      <Box display="flex" alignItems="center" mb={2}>
        <ShoppingCartIcon style={{ color: theme.palette.primary.main, marginRight: 10, fontSize: 40 }} />
        <Typography variant="h4">Carrito</Typography>
      </Box>
      {carrito.length === 0 ? (
        <div style={{ textAlign: 'center', marginTop: 50 }}>
          <ShoppingCartIcon style={{ fontSize: 150, color: 'lightgray' }} />
          <Typography variant="body1" style={{ marginTop: 20 }}>El carrito está vacío</Typography>
        </div>
      ) : (
        <>
          {carrito.map((producto, index) => (
            <Card key={index} style={{ marginBottom: 20 }}>
              <CardContent>
                <Grid container alignItems="center" spacing={2}>
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                  <Box
                      component="img"
                      src={producto.mainImage}
                      alt={`Imagen ${index + 1}`}
                      sx={{
                        width: '20%',
                        height: '20%',
                        objectFit: 'cover',
                        borderRadius: 1,
                      }}
                    />
                  <Box display="flex" flexDirection="column" style={{ marginLeft: 16 }}>
                    <Typography variant="h6">
                      {producto.nombre} {producto.color.nombre} ({producto.talla.nombre})
                    </Typography>
                    <Typography variant="body1">
                      ${producto.precio_descuento || producto.precio}
                    </Typography>
                  </Box>
                </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => removerDelCarrito(producto)}
                        style={{ width: '110px', borderRadius: 20, }}
                        startIcon={<DeleteIcon />}
                      >
                        Borrar
                      </Button>
                      <Paper style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '110px', borderRadius: 20, }}>
                        <IconButton onClick={() => reducirCantidad(producto)} aria-label="decrease">
                          <Remove />
                        </IconButton>
                        <InputBase
                          value={producto.cantidad}
                          inputProps={{ style: { textAlign: 'center' } }}
                          readOnly
                        />
                        <IconButton onClick={() => aumentarCantidad(producto)} aria-label="increase">
                          <Add />
                        </IconButton>
                      </Paper>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ))}

          {/* Cálculo del subtotal */}
          <Typography variant="h5" style={{ marginTop: 20 }}>
            Subtotal: ${subtotal.toFixed(2)}
          </Typography>

          {/* Selección de envío */}
          <RadioGroup value={envio} onChange={handleEnvioChange} style={{ marginTop: 20 }}>
            <FormControlLabel value="retiro" control={<Radio />} label="Retiro en local (Cabildo 225, Turdera)" />
            <FormControlLabel value="envio" control={<Radio />} label="Envío a domicilio"/>
          </RadioGroup>

          {envio === 'envio' && (
            <Box display="flex" flexDirection="column" style={{ marginTop: 20 }}>
              <TextField
                label="Nombre y Apellido"
                value={nombreApellido}
                onChange={(e) => setNombreApellido(e.target.value)}
                required
                style={{ marginBottom: 20 }}
              />
              <FormControl required style={{ marginBottom: 20 }}>
                <InputLabel
                  shrink
                  style={{
                    backgroundColor: 'white',
                  }}
                >
                  Provincia
                </InputLabel>
                  <Select
                    value={provincia}
                    onChange={(e) => setProvincia(e.target.value)}
                  >
                    <MenuItem value="Salta">Salta</MenuItem>
                    <MenuItem value="Buenos Aires">Buenos Aires</MenuItem>
                    <MenuItem value="CABA">Ciudad Autónoma de Buenos Aires</MenuItem>
                    <MenuItem value="San Luis">San Luis</MenuItem>
                    <MenuItem value="Entre Ríos">Entre Ríos</MenuItem>
                    <MenuItem value="La Rioja">La Rioja</MenuItem>
                    <MenuItem value="Santiago Del Estero">Santiago del Estero</MenuItem>
                    <MenuItem value="Chaco">Chaco</MenuItem>
                    <MenuItem value="San Juan">San Juan</MenuItem>
                    <MenuItem value="Catamarca">Catamarca</MenuItem>
                    <MenuItem value="La Pampa">La Pampa</MenuItem>
                    <MenuItem value="Mendoza">Mendoza</MenuItem>
                    <MenuItem value="Misiones">Misiones</MenuItem>
                    <MenuItem value="Formosa">Formosa</MenuItem>
                    <MenuItem value="Neuquén">Neuquén</MenuItem>
                    <MenuItem value="Río Negro">Río Negro</MenuItem>
                    <MenuItem value="Santa Fe">Santa Fe</MenuItem>
                    <MenuItem value="Tucumán">Tucumán</MenuItem>
                    <MenuItem value="Chubut">Chubut</MenuItem>
                    <MenuItem value="Tierra del Fuego">Tierra del Fuego</MenuItem>
                    <MenuItem value="Corrientes">Corrientes</MenuItem>
                    <MenuItem value="Córdoba">Córdoba</MenuItem>
                    <MenuItem value="Jujuy">Jujuy</MenuItem>
                    <MenuItem value="Santa Cruz">Santa Cruz</MenuItem>
                  </Select>
              </FormControl>
              <TextField
                label="Código Postal"
                value={codigoPostal}
                onChange={(e) => setCodigoPostal(e.target.value)}
                required
                style={{ marginBottom: 20 }}
              />
              <TextField
                label="Dirección (Calle y número)"
                value={direccion}
                onChange={(e) => setDireccion(e.target.value)}
                required
                style={{ marginBottom: 20 }}
              />
              
              <TextField
                label="Teléfono"
                value={telefono}
                onChange={(e) => setTelefono(e.target.value)}
                style={{ marginBottom: 20 }}
              />
              <Button 
                onClick={handleCalcularEnvio} 
                variant="contained" 
                color="primary"
                disabled={loadingEnvio}
              >
                {loadingEnvio ? 'Calculando...' : 'Calcular envío'}
              </Button>
              <Typography variant="h5" style={{ marginTop: 20 }}>
                Costo de Envio: ${costoEnvio ? costoEnvio.toFixed(2) : '---'}
              </Typography>
            </Box>
          )}

          {/* Cálculo del total */}
          <Typography variant="h5" style={{ marginTop: 20 }}>
            Total: ${total.toFixed(2)}
          </Typography>
          <Typography variant="h5" color="alert.main" style={{ marginTop: 20 }}>
            O <span style={{ fontWeight: 'bold' }}>${(subtotal * 0.91 + costoEnvio).toFixed(2)}</span> con Transferencia
          </Typography>

          <Button 
            onClick={handleBuy} 
            variant="contained" 
            color="primary" 
            startIcon={<ShoppingCartIcon />} 
            style={{ marginTop: 20, borderRadius: 20, }}
            disabled={loadingEnvio || costoEnvio === null}
          >
            Comprar
          </Button>

            {loadingBuy && !preferenceId && (
            <Box display="flex" justifyContent="center" alignItems="center" style={{ marginTop: 20 }}>
              <CircularProgress />
            </Box>
            )}

          {preferenceId && (
            <div style={{ padding: '20px' }}>
              <Wallet 
                initialization={{ preferenceId: preferenceId }} 
                customization={{
                  visual: {
                    buttonBackground: 'black',
                    borderRadius: '6px',
                  },
                }}
              />
            </div>
          )}
        </>
      )}
      <Snackbar 
        open={showSnackbar} 
        autoHideDuration={3000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbarSeverity}
          variant="filled"
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Carrito;